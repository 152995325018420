<template>
  <div class="uk-container uk-container-expand">
    <div class="uk-grid" data-uk-grid></div>
  </div>
</template>

<script>
export default {
  middleware: 'auth',
  components: {
    //
  },
  mounted () {
  }
}

</script>
